<template>
  <section
    class="tw-bg-primary-700 tw-py-7.5 md:tw-py-10 xl:tw-py-28 main-landing-subscribe-section"
  >
    <div class="tw-container">
      <div class="subscribe-section__content">
        <div class="tw-max-w-[770px]">
          <h1 class="heading-h2 xl:heading-h1 tw-mb-2.5">
            {{ t('mainPage.subscribe.title') }}
          </h1>
          <p class="tw-text-350 tw-leading-550 tw-text-primary-300 tw-mb-7.5">
            {{ t('mainPage.subscribe.subtitle') }}
          </p>
        </div>

        <subscribe-section-form />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SubscribeSectionForm from '~/shared/components/subscribe/SubscribeSectionForm.vue';

const { t } = useI18n();
</script>

<style lang="postcss">
.subscribe-section__content {
  @screen xl {
    @apply tw-bg-no-repeat tw-bg-right tw-bg-contain tw-mr-20;

    background-image: url('assets/images/subscribe-bg.png');
  }
}
</style>
