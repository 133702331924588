<template>
  <div>
    <p v-if="isSubmitted" class="tw-flex tw-items-center tw-mb-16">
      <span
        class="tw-flex tw-items-center tw-justify-center tw-bg-success-500/25 tw-w-6 tw-h-6 tw-p-1"
      >
        <de-svg-icon name="check" class="tw-text-success-500" />
      </span>
      <span class="tw-ml-3 heading-h4 tw-text-success-500">
        {{ t('mainPage.subscribe.subscriptionSuccessfullyCompleted') }}
      </span>
    </p>

    <form v-else @submit.prevent="onSubmit">
      <div class="tw-flex tw-items-start tw-mb-5">
        <de-form-input-text
          id="email"
          :placeholder="t('form.yourEmail')"
          type="email"
          wrapper-class="tw-w-full sm:tw-w-auto"
          class="tw-w-full sm:tw-w-[300px]"
        />

        <de-button
          type="submit"
          :variant="ButtonVariantOptions.confirm"
          :size="ButtonSizeOptions.medium"
          :loading="isPending"
          :label="t('common.buttons.subscribe')"
          class="tw-ml-2.5 tw-overflow-visible"
        />
      </div>

      <de-form-checkbox :value="true" name="isTermsAccepted" show-error input-id="terms" binary>
        <template #label>
          <i18n-t keypath="form.iHaveReadTerms" scope="global">
            <template #termLink>
              <nuxt-link-locale
                to="/privacy"
                class="tw-text-primary-300 tw-border-b tw-font-semibold"
              >
                {{ t('terms.privacy.title') }}
              </nuxt-link-locale>
            </template>
          </i18n-t>
        </template>
      </de-form-checkbox>
    </form>
  </div>
</template>

<script setup lang="ts">
import { object, string, boolean } from 'yup';
import { useForm } from 'vee-validate';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import {
  ButtonSizeOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';
import DeFormCheckbox from '~/shared/components/lib/form/form-checkbox/DeFormCheckbox.vue';
import DeFormInputText from '~/shared/components/lib/form/form-input/DeFormInputText.vue';
import { ref, useI18n, useNuxtApp } from '#imports';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import { CommunityService } from '~/data/services/community.service';

const { t } = useI18n();

const { handleSubmit, resetForm } = useForm({
  validationSchema: object({
    email: string()
      .required(t('form.enterYourX', { field: 'form.emailAddress' }))
      .email(t('form.rules.validEmail', { field: 'form.emailAddress' })),
    isTermsAccepted: boolean().required(t('form.rules.acceptTerms')),
  }),
});

const { $customFetch } = useNuxtApp();
const communityRepo = CommunityService($customFetch);

const isPending = ref(false);
const isSubmitted = ref(false);

const onSubmit = handleSubmit(async (values) => {
  isPending.value = true;

  try {
    await communityRepo.newsSubscribe(values.email);
    isSubmitted.value = true;
    resetForm();
  } finally {
    isPending.value = false;
  }
});
</script>
