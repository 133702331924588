import type { CustomFetch } from '~/shared/types/custom-fetch.type';

export const CommunityService = (fetch: CustomFetch) => ({
  async newsSubscribe(email: string): Promise<void> {
    return await fetch('api/community/news-subscribe', {
      method: 'POST',
      body: {
        email,
      },
    });
  },
});
